<template>

    <footer class="py-6 px-6 bg-pink-primary text-offwhite-primary flex items-center gap-4">
        <a href="/">
            <p class="font-black text-2xl tracking-tighter">SW</p>
        </a>
        <p class="font-thin text-xs">Copyright &copy;2023</p>
    </footer>
</template>
  
  <script>
  export default {
    name: 'FooterComponent',
    props: {
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>